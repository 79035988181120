import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/layout';
import 'react-responsive-modal/styles.css';
import SEO from '../components/seo';
import OtherGlossaryLinks from '../components/OtherGlossaryLinks';

export const query = graphql`
  query($id: ID!) {
    wpcontent {
      glossary(id: $id) {
        title
        uri
        content
        excerpt
        seo {
          title
          metaDesc
          canonical
        }
      }
    }
  }
`

const GlossaryTemplate = ({data}) => {

    const glossary = data.wpcontent.glossary
    const metaDesc = data.wpcontent.glossary.seo.metaDesc
    const pageTitle = data.wpcontent.glossary.seo.title
  
    return (
      <Layout className="">
        <SEO title={pageTitle}
            description={metaDesc}     
        />
        <div className='max-w-7xl mx-auto mt-5 px-5 xl:px-0'>

          <div className='flex'>
            <a href='https://www.vantagecircle.com/glossaries/' className='text-orange text-sm'>
              Glossary
            </a>
            <p className='text-sm'>&nbsp;&gt;&gt; {glossary.title}</p>
          </div>

          <div className='xl:flex pb-10 xl:pb-16'>
            <div className='w-full xl:w-8/12'>
              <h1 className='homepage-section-heading pt-4'>{glossary.title}</h1>
              <div className='' dangerouslySetInnerHTML={{ __html: glossary.content }} />
            </div>
            <div className='w-full xl:w-4/12 flex items-start justify-center xl:justify-end xl:pr-5 xl:pl-8 pt-10 xl:pt-20'>
              <a href="https://www.vantagecircle.com/request-demo/">
                <picture>
                  <source class='' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2024/04/Glossary-side-banner.png" media= "(min-width: 640px)" width="400" type="image/webp" alt="glossary-banner" />
                  <img class='' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2024/04/Glossary-side-banner.png" alt="glossary-banner" width="250" />
                </picture>
              </a>
            </div>
          </div>
          
        </div>

      </Layout>
    )
}

export default GlossaryTemplate